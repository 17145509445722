import axios from 'axios/index';

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Origin-ID': 'APP',
  },
  withCredentials: true,
});

export default http;
