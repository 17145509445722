<template>
    <v-snackbar v-model="snackbarShow"
                multi-line
                top
                :timeout="snackbarTimeout"
                :color="snackbarColor">
        {{ snackbarMessage }}
        <v-btn v-if="this.$store.state.alert.showReload" flat @click.native="$router.go">{{ $t('global.reload') }}</v-btn>
        <v-btn v-else flat @click.native="snackbarShow = false">{{ $t('global.close') }}</v-btn>
    </v-snackbar>
</template>

<script>
export default {
  name: 'v-user-notifications',
  data() {
    return {
      snackbarShow: false,
      snackbarMessage: '',
      snackbarColor: '',
      snackbarTimeout: 0,
    };
  },
  created() {
    // Watch for new messages and trigger snackbar notifications
    this.$store.watch((state) => state.alert.message, () => {
      const msg = this.$store.state.alert.message;
      if (msg !== '') {
        this.snackbarShow = true;
        this.snackbarMessage = this.$store.state.alert.message;
        this.snackbarColor = this.$store.state.alert.color;
        this.snackbarTimeout = this.$store.state.alert.showReload ? 0 : 6000;
        this.$nextTick(() => {
          this.$store.commit('SET_ALERT', { message: '', color: '' });
        });
      }
    });
  },
};
</script>

<style lang="scss">
    .v-snack__content {
        height: auto !important;
        min-height: 80px;
    }
</style>
