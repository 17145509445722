<template>
    <div>
        <!-- LOADING -->
        <v-layout v-if="loading" column align-center pt-5 pb-5>
            <v-progress-circular
                    :size="36"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
            <h2 class="title text--secondary text-xs-center mt-3">{{ $t('global.state_loading') }}</h2>
        </v-layout>

        <!-- ERROR -->
        <v-layout v-else-if="error" wrap pt-2 pb-5>
            <v-flex xs2 class="text-xs-center">
                <v-icon large color="primary">fa-exclamation-circle</v-icon>
            </v-flex>
            <v-flex xs10>
                <h2 class="subheading font-weight-bold mb-2">{{ $t('scanner.error_title') }}</h2>
                <p class="body-1">{{ $t('scanner.error_body') }}</p>
            </v-flex>

            <!-- MANUAL CODE INPUT -->
            <div v-if="enableQrcode && enableBarcode" class="c-error-manual">
                <v-code-input @result="getScannerResult"></v-code-input>
            </div>
        </v-layout>

        <!-- SHOW MANUAL INPUT -->
        <v-layout v-else-if="showManualInput" wrap pt-2 pb-5>
            <v-flex xs12>
                <v-btn class="btn-close" small flat icon color="primary" @click.native="$emit('reset')">
                    <v-icon>fa-times</v-icon>
                </v-btn>
                <h2 class="headline font-weight-bold mb-2">{{ $t('manual.title') }}</h2>
                <p class="mb-1">{{ $t('manual.body') }}</p>
            </v-flex>

            <!-- MANUAL CODE INPUT -->
            <v-code-input @result="getScannerResult"></v-code-input>
        </v-layout>

        <!-- LOAD VIDEO STREAM -->
        <div v-else
             :class="{'c-video-wrapper': true, 'c-video-wrapper--tablet-portrait': tabletPortrait, 'c-video-wrapper--tablet-landscape': tabletLandscape }">
          <video  id="scanner"
                    class="scanner" ref="scanner" :width="width"
                   :height="$vuetify.breakpoint.xs && !forceHeight ? '280' : height"></video>
            <div class="laser"></div>
            <v-btn v-if="showBolt" :color="bolt ? 'primary' : 'transparent'" icon large class="c-flashlight-icon" @click="setTorch">
                <v-icon color="white">fa-bolt</v-icon>
            </v-btn>
          <v-btn v-if="devices && devices.length > 1" icon large class="c-camera-icon" @click="switchCamera">
            <v-icon color="white">fa-camera</v-icon>
          </v-btn>
        </div>
    </div>
</template>

<script>
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library';
import { Howl } from 'howler';
import VCodeInput from '@/components/CodeInput.vue';
import { mapState } from 'vuex';

export default {
  name: 'MultiFormatReader',
  components: {
    VCodeInput,
  },
  props: {
    width: { type: String, default: '1280' },
    height: { type: String, default: '720' },
    enableQrcode: { type: Boolean, default: true },
    enableBarcode: { type: Boolean, default: true },
    showManualInput: { type: Boolean, default: false },
    torch: { type: Boolean, default: true },
    forceHeight: { type: Boolean, default: false },
  },
  data() {
    return {
      scanner: null,
      loading: false,
      error: false,
      hints: null,
      bolt: false,
      devices: [],
      deviceIndex: 0,
      failedDevices: [],
      showBolt: navigator.mediaDevices.getSupportedConstraints().torch && this.torch,
    };
  },
  created() {
    // Disable barcode
    if (!this.enableBarcode) {
      const formats = [BarcodeFormat.QR_CODE];
      this.hints = new Map();
      this.hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    }
  },
  computed: {
    tabletPortrait() {
      return this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height;
    },
    tabletLandscape() {
      return this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height;
    },
    deviceId() {
      if (this.devices?.[this.deviceIndex]?.deviceId) {
        this.$store.dispatch('setLastDeviceId', this.devices?.[this.deviceIndex]?.deviceId);
      }

      return this.devices?.[this.deviceIndex]?.deviceId;
    },
    ...mapState(['lastDeviceId']),
  },
  async mounted() {
    this.$nextTick(async () => {
      navigator.mediaDevices.addEventListener('devicechange', this.updateDevices);
      await this.updateDevices();
    });
  },
  watch: {
    error(value) {
      this.$emit('cameraError', value);
    },
    showManualInput(value) {
      if (value) {
        this.resetScanner();
      }
    },
  },
  methods: {
    async updateDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.devices.splice(0);
      this.$nextTick(async () => {
        this.devices.push(...devices.filter((d) => d.kind === 'videoinput'
          && d.label !== 'Desk View Camera'
          && d.label !== 'Back Dual Wide Camera'));

        // Set the default index to the index with label "back camera"
        let index = this.devices.findIndex((device) => device.label === 'Back Camera');
        if (index < 0) {
          index = 0;
        }

        if (this.lastDeviceId) {
          const lastDeviceIndex = this.devices.findIndex((device) => device.deviceId === this.lastDeviceId);

          if (lastDeviceIndex >= 0) {
            index = lastDeviceIndex;
          }
        }

        this.deviceIndex = index;

        this.showBolt = navigator.mediaDevices.getSupportedConstraints().torch && this.torch;
        await this.initScanner();
      });
    },
    switchCamera() {
      const newIndex = this.deviceIndex + 1;

      if (newIndex > (this.devices.length - 1)) {
        this.deviceIndex = 0;
      } else {
        this.deviceIndex = newIndex;
      }
      this.initScanner();
    },
    // init default scanner with QR and Barcode
    // hints = null = instance loads all formats
    async initScanner() {
      this.scanner = new BrowserMultiFormatReader(this.hints);

      try {
        const result = await this.scanner.decodeOnceFromVideoDevice(this.deviceId, this.$refs.scanner);
        this.getScannerResult(result);
      } catch (e) {
        // An error occurred, try the next device until all devices were tested.
        if (this.failedDevices.length < this.devices.length) {
          if (!this.failedDevices.includes(this.deviceId)) {
            this.failedDevices.push(this.deviceId);
          }

          this.switchCamera();
          return;
        }

        this.error = !this.showManualInput;
      }
    },

    // get result
    getScannerResult(result) {
      this.playBeep();
      this.$emit('result', result);
    },

    // play sound for success and error state
    playBeep() {
      const sound = new Howl({
        src: ['audio/beep.mp3'],
        autoplay: true,
        volume: 1,
      });
      sound.play();
    },

    setTorch() {
      this.bolt = !this.bolt;
      this.scanner.videoElement.srcObject.getVideoTracks()[0].applyConstraints({ advanced: [{ torch: this.bolt }] });
    },

    resetScanner() {
      this.scanner.reset();
    },
  },
  beforeDestroy() {
    this.resetScanner();
  },
};
</script>

<style lang="scss" scoped>
    .scanner {
        max-width: 100%;
        object-fit: cover;
        border-radius: 4px;
    }

    .c-video-wrapper {
        position: relative;
        height: auto;
        overflow: hidden;

        &--tablet-portrait {
            height: 65vh;
        }

        &--tablet-landscape {
            height: 55vh;
        }

        video {
            min-width: 100%;
            min-height: 100%;
        }

        .laser {
            width: 90%;
            margin-left: 5%;
            background-color: red;
            height: 1px;
            position: absolute;
            top: 10%;
            z-index: 3;
            animation: scanning 3s infinite;
        }
    }

    .c-flashlight-icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;
        z-index: 3;
    }

    .c-camera-icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 44px;
      z-index: 3;
    }

    .c-error-manual {
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: 2px solid #efefef;
    }

    .btn-close {
        position: absolute;
        right: 10px;
        top: 20px;
    }

    .ex-images {
        max-width: 100%;
    }

    @keyframes scanning {
        50% {
            transform: translateY(240px);
        }
    }
</style>
