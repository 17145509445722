import Vue from 'vue';
import {
  Vuetify,
  VApp,
  VBottomNav,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VDialog,
  VDivider,
  VCard,
  VCarousel,
  VIcon,
  VGrid,
  VCheckbox,
  VToolbar,
  VProgressCircular,
  VProgressLinear,
  VSnackbar,
  VForm,
  VTextField,
  VInput,
  VImg,
  VSelect,
  transitions,
} from 'vuetify';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  components: {
    VApp,
    VBottomNav,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VDialog,
    VDivider,
    VCard,
    VCarousel,
    VIcon,
    VGrid,
    VCheckbox,
    VToolbar,
    VProgressCircular,
    VProgressLinear,
    VSnackbar,
    VForm,
    VTextField,
    VInput,
    VImg,
    VSelect,
    transitions,
  },
  iconfont: 'md',
  theme: {
    primary: '#CD1719',
    secondary: '#A89677',
    accent: '#232728',
  },
  options: {
    themeVariations: ['primary', 'secondary', 'accent'],
  },
});
