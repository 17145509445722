<template>
    <v-form v-model="valid" @submit.prevent="submit">
        <v-layout wrap class="c-code__input">
            <v-flex xs12 mb-1>
                <template v-if="title"><h2 class="subheading fon font-weight-medium mb-2">{{ title }}</h2></template>
                <template v-if="!title && personality.fittingsAllowed"><h2 class="subheading font-weight-medium mb-2">{{ $t('code_input.title') }}</h2></template>
              <template v-if="!title && !personality.fittingsAllowed"><h2 class="subheading font-weight-medium mb-2">{{ $t('code_input.title_no_fitting') }}</h2></template>
            </v-flex>
            <v-flex xs12>
                <v-text-field
                        v-model="code"
                        class="v-text-field--vh"
                        color="primary"
                        solo
                        hide-details
                        :disabled="disabled"
                        :placeholder="$t('code_input.placeholder')"
                >
                    <template slot="append">
                        <v-btn type="submit" color="secondary" depressed :disabled="code.length < 3">
                            {{ $t('global.add') }}
                        </v-btn>
                    </template>
                </v-text-field>
                <div class="c-code__input-hint">{{ $t('code_input.hint') }}</div>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CodeInput',
  props: {
    title: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['personality']),
  },
  data() {
    return {
      valid: true,
      code: '',
    };
  },
  methods: {
    submit() {
      const value = this.code.replace(/ /g, '');
      const format = value.length === 13 ? 7 : 11; // EAN and QR format for CodeReader
      this.$emit('result', {
        format,
        text: value,
      });
    },
  },

};
</script>

<style lang="scss">
    .c-code__input {
        background-color: #fff;
        border: 1px solid rgba(211,211,211,.46);
        padding: 20px 12px;

        &-hint {
            margin-top: 10px;
            font-size: 10px;
            text-align: center;
            color: rgba(0, 0, 0, 0.54) !important;
        }

        .v-messages__message {
            line-height: 1.25;
        }
        .v-messages.error--text {
            color: rgba(0,0,0,0.54) !important;
        }
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
            padding-left: 20px;
            padding-right: 0;
        }
    }
</style>
