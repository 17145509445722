<template>
    <v-form class="c-search-input-wrapper">

        <!-- autocomplete search input -->
        <v-text-field
                v-model="searchQuery"
                class="c-search-input"
                color="primary"
                placeholder="Zoek op naam, type, productnummer"
                hide-details
                solo
        >
            <template slot="prepend-inner">
                <v-icon color="accent" small>fa-search</v-icon>
            </template>
            <template slot="append">
                <v-btn v-if="searchQuery" class="v-btn--close" color="accent" icon flat
                       @click.native="searchQuery = ''">
                    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="UX-PWA-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Searchresults" transform="translate(-299.000000, -85.000000)">
                                <g id="header-vanhoecke-v4" transform="translate(0.000000, 68.000000)">
                                    <g id="Group-6" transform="translate(299.000000, 17.000000)">
                                        <circle id="Oval" stroke-opacity="0.462862319" stroke="#D3D3D3" fill="#F8F8F8"
                                                cx="10" cy="10" r="9.5"></circle>
                                        <path d="M6.6266431,14 C6.79036382,14 6.94265782,13.9413299 7.07163004,13.8120679 L9.99855955,10.8785616 L12.9254891,13.8120679 C13.0425662,13.9294081 13.206287,14 13.370476,14 C13.5341967,14 13.6864907,13.9413299 13.8154629,13.8120679 C14.0615124,13.5654656 14.0615124,13.1666965 13.8154629,12.932016 L10.8885334,9.99850978 L13.8154629,7.06500356 C14.0615124,6.8184013 14.0615124,6.4196322 13.8154629,6.1849517 C13.5694135,5.93834943 13.1834335,5.93834943 12.9373841,6.1849517 L10.0104546,9.11845791 L7.08352508,6.1849517 C6.83747568,5.93834943 6.45149563,5.93834943 6.20544623,6.1849517 C5.95939683,6.43155397 5.95939683,6.83032307 6.20544623,7.06500356 L9.13237574,9.99850978 L6.20544623,12.932016 C5.93561845,13.1786183 5.93561845,13.5654656 6.18166551,13.8120679 C6.29873333,13.9294081 6.46245406,14 6.6266431,14 Z"
                                              id="Path" stroke="#979797" fill="#373B3B"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </v-btn>
            </template>
        </v-text-field>

        <!-- autocomplete items fetched from the server -->
        <v-list v-if="autocompleteItems" class="c-search pa-0" two-line>
            <template v-if="autocompleteItems.length">
                <template v-for="(item) in autocompleteItems" :id="item.value">
                    <v-list-tile :key="item.title" avatar @click="getArticleInfo(item.value)">
                        <v-list-tile-avatar>
                            <img :src="item.image" :alt="item.label">
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                            <v-list-tile-title v-html="item.label" class="font-weight-medium text--lighten-4"></v-list-tile-title>
                        </v-list-tile-content>
                        <v-list-tile-action>
                            <v-icon>fa-angle-right</v-icon>
                        </v-list-tile-action>
                    </v-list-tile>
                    <v-divider></v-divider>
                </template>
            </template>
            <template v-else>
                <v-list-tile class="py-3">
                    <v-list-tile-content class="font-italic d-block">
                        {{ $t('global.search_query') }} <strong>{{ searchQuery }}</strong> bevat <strong>0</strong> {{ $t('global.results') }}.<br>
                        {{ $t('search.caption') }}
                    </v-list-tile-content>
                </v-list-tile>
            </template>

            <div v-if="autocompleteItems" class="text-xs-center mt-2">
                <v-btn outline depressed color="accent" @click.native="searchQuery = ''">
                    {{ $t('global.close') }}
                </v-btn>
            </div>
        </v-list>

        <!-- loading screen -->
        <div v-if="loading" class="list-loading text-xs-center">
            <v-progress-circular
                    class="mt-5"
                    :size="36"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>
    </v-form>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';

export default {
  name: 'v-search',
  data() {
    return {
      loading: false,
      searchQuery: null,
    };
  },
  computed: {
    ...mapState(['autocompleteItems']),
  },
  watch: {
    // eslint-disable-next-line func-names
    searchQuery: debounce(function (newValue) {
      this.loading = true;
      if (newValue && newValue.length > 2) {
        this.$store.dispatch('getAutocomplete', newValue).finally(() => {
          this.loading = false;
        });
      } else {
        this.$store.dispatch('clearAutocomplete');
        this.loading = false;
      }
    }, 300),
    autocompleteItems(value) {
      if (!value) { this.searchQuery = null; }
    },
  },
  methods: {
    getArticleInfo(value) {
      this.$store.dispatch('getArticleInfo', { value, type: 'id' });
    },
  },
};
</script>

<style lang="scss">
    .c-search-input-wrapper {
        width: 100%;
        margin-left: 5%;
        padding: 10px 0;
    }

    .c-search {
        position: fixed;
        top: 55px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 55px);
        overflow-y: scroll;
    }

    .c-search-input {
        &.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
            box-shadow: none
        }

        &.v-text-field.v-text-field--solo .v-input__control {
            min-height: 36px;
        }

        &.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
            padding-left: 5px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .v-btn--close {
        position: absolute !important;
        top: 0;
        right: 0;
        margin: 0 !important;
    }

    .list-loading {
        position: absolute;
        top: 55px;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255,255,255,.75);
        z-index: 100;
    }
</style>
