<template>
    <v-bottom-nav
            :active.sync="bottomNav"
            app
            height="70"
            color="white"
            :value="true"
    >
        <v-btn value="contacts" color="dark" flat>
            <span>{{ $t('nav.contacts') }}</span>
        </v-btn>

        <v-btn value="home" flat color="dark">
            <span>{{ $t('nav.scanner') }}</span>
            <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="footer" transform="translate(-180.000000, -17.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g id="Group">
                            <g id="np_viewfinder_845340_000000" transform="translate(180.000000, 17.000000)">
                                <path d="M11.8343461,15.986935 L10.2320236,15.9825599 L10.2320236,13.4137511 L13.4278593,13.4137511 L13.4278593,10.2212601 L15.9993593,10.2212601 L15.9993593,16 L14.7180139,15.9956249 C14.0135302,15.9931249 12.7159117,15.989375 11.8343461,15.986875 L11.8343461,15.986935 Z M3.55271368e-15,13.1019651 L3.55271368e-15,10.2213152 L2.57150008,10.2213152 L2.57150008,13.4138061 L5.78479403,13.4138061 L5.78479403,15.982615 L3.55271368e-15,15.982615 L3.55271368e-15,13.1019651 Z M5.69031672e-16,2.88944994 L0,0 L5.78479403,0 L5.78479403,2.56880884 L2.57150008,2.56880884 L2.57150008,5.77873988 L0,5.77873988 L5.69031672e-16,2.88944994 Z M13.4284999,4.17382236 L13.4284999,2.56885684 L10.2326643,2.56885684 L10.2326643,4.80001651e-05 L16,4.80001651e-05 L16,5.77878788 L13.4284999,5.77878788 L13.4284999,4.17382236 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </v-btn>

        <v-btn value="cart" color="dark" flat>
            <span>{{ $t('nav.cart') }}</span>
        </v-btn>
    </v-bottom-nav>
</template>

<script>
export default {
  name: 'v-navigation-mobile',
  data() {
    return {
      bottomNav: this.$route.name || 'home',
    };
  },
  watch: {
    '$route.name': function (value) {
      if (value === 'cart-confirmation') { value = 'cart'; }
      if (value === 'cart-finished') { value = 'cart'; }
      this.bottomNav = value;
    },
    bottomNav(value) {
      this.$router.push({
        name: value,
      }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
    .v-bottom-nav .v-btn[value=home] {
        position: fixed;
        bottom: 15px;
        padding: 5px;
        width: 55px;
        border: 1px solid rgba(211,211,211,0.46);
        background: #fff !important;
        z-index: 100;

        .v-btn__content {
            position: absolute;
            left: 5px;
            top: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: var(--accent-color);
            span {
                color: white;
                padding-top: 8px;
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
    .v-bottom-nav .v-btn[value=contacts] .v-btn__content {
        text-indent: -50px;
        font-size: 14px;
        font-weight: 500;
    }
    .v-bottom-nav .v-btn[value=cart] .v-btn__content {
        text-indent: 50px;
        font-size: 14px;
        font-weight: 500;
    }
    .v-bottom-nav .v-btn--active {
        color: var(--accent-color);
    }
</style>
