<template>
    <v-dialog v-model="dialogLandscape" persistent fullscreen>
        <v-card class="pa-2">
            <v-card-title class="headline text-xs-center">{{ $t('global.landscape_title') }}</v-card-title>
            <v-card-text>
                <v-layout wrap>
                    <v-flex xs12>
                        <div v-html="$t('global.landscape_content')"></div>
                    </v-flex>
                    <v-flex xs12 class="text-xs-center py-5">
                        <v-icon x-large>fa-sync</v-icon>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" fixed bottom class="v-btn--right" large @click.native="dialogLandscape = false">
                    {{ $t('global.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'v-orientation-dialog',
  data() {
    return {
      dialogLandscape: false,
    };
  },
  created() {
    this.applyOrientation();
  },
  mounted() {
    const view = this;
    // Listen for orientation changes
    window.addEventListener('orientationchange', () => {
      // Announce the new orientation number
      view.applyOrientation();
    }, false);
  },
  methods: {
    applyOrientation() {
      // eslint-disable-next-line no-restricted-globals
      this.dialogLandscape = screen.height < screen.width && this.$vuetify.breakpoint.width < 767;
    },
  },
};
</script>
