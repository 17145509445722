<template>
    <v-navigation-drawer v-model="settings.drawer" fixed right app>
        <v-layout column pa-4 class="text-xs-right">
            <v-flex xs12 mb-3 v-if="$store.state.isLoggedIn">
                <h3 class="body-2 account--label">{{ $t('nav.logged_in_as') }}</h3>
                <span class="account--title">{{ currentUser ? currentUser.name : '' }}</span>
            </v-flex>
            <v-flex xs12 mb-3 v-if="$store.state.isLoggedIn">
                <h3 class="body-2 account--label">{{ $t('nav.logout') }}</h3>
                <v-btn outline
                       @click.stop="dialogLogout = true"
                       color="accent"
                       class="c-btn--logout ml-0"
                >
                    {{ $t('global.logout') }}
                    <v-icon dark small right color="accent">fa-sign-out-alt</v-icon>
                </v-btn>

                <v-dialog v-model="dialogLogout" persistent>
                    <v-card class="pa-2">
                        <v-card-title>{{ $t('global.logout_title') }}</v-card-title>
                        <v-card-actions>
                            <v-btn color="accent" flat @click.native="dialogLogout = false">
                                {{ $t('global.cancel') }}
                            </v-btn>
                            <v-btn color="secondary" class="right" block large @click.native="logout">
                                {{ $t('global.logout') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-flex xs12>
                <h3 class="body-2 account--label mb-1">{{ $t('nav.change_language') }}</h3>
                <select v-model="$i18n.locale" class="locale-changer font-weight-medium accent--text">
                    <option v-for="(lang, i) in personality.languages" :key="`Lang${i}`" :value="lang.code">
                        {{ lang.text }}
                    </option>
                </select>
            </v-flex>
            <v-container class="caption pa-4 sidebar-bottom-links">
              <v-container v-if="personality.contactInfo" style="margin-bottom: 1rem;" class="font-000--book pa-0" v-html="personality.contactInfo"></v-container>
              <v-container v-for="(legalUrl, legalLinkName, index) in personality.legalLinks" :key="`link-${index}`" class="pa-0">
                <a class="font-000--book color--fog" style="font-size: 10px;" :href="legalUrl" target="_blank">{{ $t(`legal.${legalLinkName}`) }}</a>
              </v-container>

          </v-container>
        </v-layout>

    </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'v-navigation-right',
  data() {
    return {
      dialogLogout: false,
      logoutLoading: false,
    };
  },
  created() {
    // Set language from cookies
    this.$i18n.locale = this.$cookie.get('i18n_locale') || this.$i18n.locale;
  },
  computed: {
    ...mapState([
      'settings',
      'personality',
    ]),
    currentUser() {
      return this.$store.state.user.current_user;
    },
  },
  watch: {
    '$i18n.locale': function (value) {
      this.$cookie.set('i18n_locale', value);
    },
  },
  methods: {
    logout() {
      this.logoutLoading = true;
      this.$store.dispatch('logout').then(() => {
        this.logoutLoading = false;
        this.$router.push({ name: 'login' }).catch(() => {});
      }).catch(() => {
        this.logoutLoading = false;
        this.$router.push({ name: 'login' }).catch(() => {});
      });
      this.dialogLogout = false;
    },
  },
};
</script>

<style lang="scss" scoped>
    .v-navigation-drawer--is-mobile {
        width: 220px !important;
    }
    .c-btn--logout {
        font-size: 13px;
        text-transform: capitalize;
        margin-right: 0;
        border: 1px solid rgba(211,211,211,0.46);
        .v-icon--left {
            margin-right: 5px;
        }
    }
    .account--label {
        font-size: 12px !important;
        color: #737373;
        font-weight: normal;
    }
    .account--title {
        font-weight: bold;
    }
    .locale-changer {
        border: 1px solid rgba(211,211,211,0.46);
        padding: 8px 16px;
        min-width: 125px;
    }
    .sidebar-bottom-links {
      position:absolute;
      bottom:0;
      right:0;
    }
</style>
