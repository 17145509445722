<template>
    <div v-if="isIOS && iosPrompt" class="ios-prompt">
        <v-icon>far fa-plus-square</v-icon>
        {{ $t('global.ios_prompt_prefix') }} <img width="16" :src="`${publicPath}images/ios-share.png`" alt=""> {{ $t('global.ios_prompt_suffix') }}
        <v-btn color="primary" class="right mt-3" small outline @click.native="setPromptCookie">
            {{ $t('global.close') }}
        </v-btn>
    </div>
</template>

<script>
export default {
  name: 'v-ios-prompt',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      iosPrompt: true,
      isIOS: this.$ua.os.name === 'iOS',
    };
  },
  created() {
    // Set ios prompt banner
    this.iosPrompt = this.$cookie.get('ios_prompt') !== 'false';
  },
  methods: {
    setPromptCookie() {
      this.$cookie.set('ios_prompt', 'false', { expires: '1Y' });
      this.iosPrompt = false;
    },
  },
};
</script>

<style lang="scss" scoped>
    .ios-prompt {
        position: fixed;
        bottom: 15px;
        left: 5vw;
        right: 5vw;
        width: 90vw;
        height: 100px;
        background-color: #efefef;
        color: #333;
        padding: 15px;
        z-index: 100;
        &:after {
            content:'';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-top: solid 15px #efefef;
            border-left: solid 15px transparent;
            border-right: solid 15px transparent;
        }
    }
</style>
