<template>
    <div id="photo-camera">
        <div class="c-camera-wrapper mb-2">
            <video ref="video" class="c-camera" width="1280" autoplay playsinline></video>
            <div class="capture--btn-wrapper text-xs-center">
                <v-btn fab flat icon large dark @click.native="capture" :disabled="captures.length > 2">
                    <v-icon>fa-camera</v-icon>
                </v-btn>
            </div>
        </div>
        <v-layout row wrap mb-4 style="height: 60px;">
            <v-flex xs4 v-for="(c, index) in captures" :key="index" class="c-capture-image text-xs-center">
                <img :src="c" height="60" alt="Foto van de schade" @click="removeCapture(index)" />
                <v-btn class="close-icon pa-0"  flat icon color="#fff" @click.native="removeCapture(index)">
                    <v-icon color="#fff">fa-times-circle</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>
        <v-layout row wrap mb-3>
            <v-flex xs12>
                <v-btn class="right" block depressed large color="secondary" :disabled="!captures.length" @click.native="send">
                    {{ $t('global.send') }}
                </v-btn>
            </v-flex>
        </v-layout>
        <canvas ref="canvas" id="canvas" :width="videoWidth" :height="videoHeight"></canvas>
    </div>
</template>

<script>
import { Howl } from 'howler';

export default {
  name: 'PhotoCamera',
  data() {
    return {
      video: {},
      canvas: {},
      captures: [],
      videoWidth: null,
      videoHeight: null,
      showCameraBtn: true,
    };
  },
  mounted() {
    const view = this;
    this.video = this.$refs.video;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
        },
      }).then((stream) => {
        try {
          this.video.srcObject = stream;
        } catch (error) {
          this.video.src = window.URL.createObjectURL(stream);
        }
        this.video.play();
        this.video.onloadedmetadata = function () {
          view.videoWidth = view.video.videoWidth;
          view.videoHeight = view.video.videoHeight;
        };
      });
    }
  },
  methods: {
    capture() {
      if (this.captures.length > 2) { return; }

      // Capture image and add to list
      this.playShutter();
      this.canvas = this.$refs.canvas;
      this.canvas.getContext('2d').drawImage(this.video, 0, 0);
      this.captures.push(this.canvas.toDataURL('image/png'));
    },
    removeCapture(index) {
      this.captures.splice(index, 1);
    },
    playShutter() {
      this.video.pause(); // Pause video

      const sound = new Howl({
        src: ['audio/shutter.mp3'],
        autoplay: true,
        volume: 1,
      });

      sound.play();
      sound.on('end', () => {
        this.video.play();
      });
    },
    send() {
      this.$emit('result', this.captures);
    },
  },
  beforeDestroy() {
    this.video = null;
    this.canvas = null;
  },
};
</script>

<style lang="scss" scoped>
    .c-camera-wrapper {
        position: relative;
        max-height: 40vh;
        overflow: hidden;
        .capture--btn-wrapper {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .c-camera {
        max-width: 100%;
    }
    .c-capture-image {
        position: relative;
        .close-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0.9;
            width: auto;
            height: auto;
            margin: 0;
            transform: translateX(-50%) translateY(-50%);
            box-shadow: 0 0 10px #999;
        }
    }
    #canvas {
        display: none;
    }
</style>
