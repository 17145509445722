<template>
    <v-app :style="personalityCss">
        <div v-if="!loading && personality.personalityId">
            <template>
                <!-- navigation, includes logout, language switch -->
                <v-navigation-right></v-navigation-right>

                <!-- fixed toolbar (nav) -->
                <v-toolbar class="c-navbar" color="primary" height="55" fixed app flat>
                    <v-logo :small="showSearchBar"></v-logo>
                    <v-spacer></v-spacer>
                    <v-search v-if="showSearchBar"></v-search>
                    <v-spacer></v-spacer>
                    <!-- trigger v-navigation-right -->
                    <v-btn flat icon color="white" @click.stop="setSettings({ drawer: !settings.drawer })"><v-icon>fa-cog</v-icon></v-btn>
                </v-toolbar>
            </template>

            <!-- content, router-view is mandatory -->
            <v-content>
                <router-view />
            </v-content>

            <template v-if="$store.state.isLoggedIn">
                <!-- navigation mobile [contacts, scanner, cart ] -->
                <v-navigation-mobile></v-navigation-mobile>

                <!-- modal of one product, requested by the user -->
                <v-product-dialog></v-product-dialog>

                <!-- notifications to user from the application feedback -->
                <v-user-notifications></v-user-notifications>

                <!-- modal with a warning to use the app in portrait mode -->
                <v-orientation-dialog></v-orientation-dialog>

                <!-- prompt the ios-user on how to install the icon to home screen -->
                <v-ios-prompt></v-ios-prompt>
            </template>
        </div>
        <template v-else>
            <v-layout column align-center pt-5 pb-5>
                <!-- notifications to user from the application feedback -->
                <v-user-notifications></v-user-notifications>

                <!-- Loading indicator -->
                <v-progress-circular
                    :size="36"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </template>

        <!-- log and watch errors on production -->
        <v-logrocket></v-logrocket>
    </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VLogo from '@/components/Layout/Logo.vue';
import VIosPrompt from '@/components/Layout/IosPrompt.vue';
import VNavigationRight from '@/components/Layout/NavigationRight.vue';
import VNavigationMobile from '@/components/Layout/NavigationMobile.vue';
import VSearch from '@/components/Search.vue';
import VUserNotifications from '@/components/UserNotifications.vue';
import VProductDialog from '@/components/Dialog/ProductDialog.vue';
import VOrientationDialog from '@/components/Dialog/OrientationDialog.vue';
import VLogrocket from '@/components/Logrocket.vue';
import i18n from './i18n';

export default {
  name: 'App',
  components: {
    VLogo,
    VSearch,
    VUserNotifications,
    VProductDialog,
    VOrientationDialog,
    VIosPrompt,
    VNavigationRight,
    VNavigationMobile,
    VLogrocket,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: false,
      init: true,
    };
  },
  computed: {
    ...mapState([
      'settings',
      'personality',
    ]),
    personalityCss() {
      return {
        '--accent-color': `${this.personality.accentColor}`,
      };
    },
    // Determine if the search bar is shown or not. This defines if the small logo should be used.
    showSearchBar() {
      return this.$store.state.isLoggedIn && this.personality.fittingsAllowed;
    },
  },
  methods: {
    ...mapActions([
      'setSettings',
    ]),

    // Login with dataopt & ref.
    async dataOptLogin() {
      // Exit early if no data opt login.
      if (!this.$route.query || !this.$route.query.DATAOPT || !this.$route.query.REF || !this.$route.query.Taal) {
        return;
      }

      // Map VH languages to our language prefixes.
      const langMap = {
        D: 'de',
        E: 'en',
        N: 'nl',
        F: 'fr',
      };
      const language = langMap[this.$route.query.Taal] || 'en';

      // Set the language.
      this.$i18n.locale = language;
      this.$cookie.set('i18n_locale', language);

      // Login call.
      try {
        await this.$store.dispatch('login', {
          DATAOPT: this.$route.query.DATAOPT,
          REF: this.$route.query.REF,
          Taal: this.$route.query.Taal,
        });

        this.$gtm.trackEvent({
          event: 'Login',
          action: 'Dataopt',
          external_ip: this.personality.external_ip,
        });

        // Redirect to home.
        await this.$router.push('/').catch(() => {});
      } catch (err) {
        // Login failed.
      }
    },
  },
  // Initialize pwa & set theme colors.
  async created() {
    this.loading = true;
    // Set locale from cookie.
    this.$i18n.locale = this.$cookie.get('i18n_locale') || this.$i18n.locale;

    await this.$store.dispatch('initPwa', { setLocale: true });

    // Set favicons / apple touch icons.
    if (this.personality && this.personality.personalityId !== 1) {
      document.querySelectorAll('[rel="apple-touch-icon"]')[0].href = `${this.personality.shopUrl}/${i18n.locale}/multi/app-icon.png?size=152x152`;
      document.querySelectorAll('[rel="icon"][sizes="32x32"]')[0].href = `${this.personality.shopUrl}/${i18n.locale}/multi/app-icon.png?size=32x32`;
      document.querySelectorAll('[rel="icon"][sizes="16x16"]')[0].href = `${this.personality.shopUrl}/${i18n.locale}/multi/app-icon.png?size=16x16`;
    } else {
      document.querySelectorAll('[rel="apple-touch-icon"]')[0].href = '/images/icons/apple-touch-icon-152x152.png';
      document.querySelectorAll('[rel="icon"][sizes="32x32"]')[0].href = '/images/icons/favicon-32x32.png';
      document.querySelectorAll('[rel="icon"][sizes="16x16"]')[0].href = '/images/icons/favicon-16x16.png';
    }

    // Set page title
    document.title = `${this.personality.readableName} Scanner`;

    // Override primary color if it's set.
    if (this.personality.accentColor) {
      this.$vuetify.theme.primary = `${this.personality.accentColor}`;
    }

    // Login with dataopt and Ref
    await this.dataOptLogin();

    this.init = false;

    this.loading = false;
  },
  watch: {
    '$i18n.locale': async function () {
      if (this.init) {
        return;
      }
      await this.$store.dispatch('initPwa', { setLocale: false });
    },
  },
};
</script>

<style lang="scss">
    // Cookie
    #onetrust-consent-sdk {
      font-family: Roboto,sans-serif!important;
    }

    // General layout
    .application.theme--light {
        background: #F8F8F8;
    }

    .c-navbar {
        outline: 1px solid hsla(0,0%,92%,.5);
        z-index: 5;
    }

    // Buttons
    .v-btn:not(.v-btn--icon) {
        text-transform: initial;
        border-radius: 0;
    }

    .v-btn--large {
        height: 55px;
    }

    .v-btn--align-left .v-btn__content {
        justify-content: left;
    }

    // Input
    .v-text-field--vh {
        &.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
            background: #F8F8F8;
            border: 1px solid rgba(211,211,211,0.46);
            box-shadow: inset 0 1px 3px 0 rgba(222,222,222,0.50);
        }
        &.v-input input::placeholder {
            font-size: 13px;
            font-weight: bold;
        }
    }

    // Van Hoecke Titels
    .title--vh {
        padding-bottom: 10px;
        &:after {
            content: "";
            display: block;
            position: relative;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 4px;
            background-color: var(--accent-color);
        }
    }
    .caption {
        color: #737373;
        font-weight: 500;
    }

    // Autocomplete fields
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        font-size: 15px;
        font-weight: 500;
        -webkit-text-fill-color: inherit;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    // Product amount
    .c-article-amount {
        background: #F8F8F8;
        border: 1px solid rgba(211, 211, 211, 0.46);
        border-radius: 20px;

        .btn__quantity {
            left: 5px;
            top: 4px;
            min-width: 32px;
            margin: 0;
            height: 32px;
            border-radius: 50% !important;
            border: 1px solid rgba(211, 211, 211, 0.46);
            background: #fff !important;

            &:last-child {
                left: -5px;
            }
        }

        .number--custom {
            font-size: 14px;
            font-weight: 500;

            &.v-text-field .v-input__control {
                min-height: 40px;
            }

            &.v-text-field .v-input__slot {
                box-shadow: none !important;
                border: 0;
                background: transparent;
                padding: 0 10px;
                min-height: 40px;
            }

            &.v-input input {
                max-height: 40px;
                line-height: 15px;
                padding: 4px 0 4px;
                text-align: center;
            }
        }
    }

    // Carousel changes
    .v-carousel.damage-carousel {
        max-width: 100%;
        width: 100%;
        height: 250px !important;
        box-shadow: none;
        .v-image__image--cover {
            min-width: auto;
            max-width: 100%;
        }
    }

    // Utility Helper Classes
    .position-absolute {
        position: absolute !important;
    }
    .position-relative {
        position: relative !important;
    }
</style>
