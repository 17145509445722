<template>
    <v-dialog v-model="open" persistent>
        <v-card v-if="article.product">
            <template v-if="!loading">
                <v-img :src="article.product.imageData.large" :contain="true" max-height="180px"></v-img>
                <v-card-title class="title">{{ article.product.name }}</v-card-title>
                <v-card-text class="pt-0">
                    <v-layout v-if="article.product.unitPrice >= 0" row mb-2>
                        <v-flex xs4 pt-2>{{ $t('global.unit_price') }}</v-flex>
                        <v-flex xs6 pt-2 class="subheading font-weight-bold">{{ article.product.unitPrice | currency }}</v-flex>
                    </v-layout>
                    <v-layout v-if="article.product.suggestedQuantityMultiplier >1" row align-center>
                        <v-flex xs4>{{ $t('global.packing') }}</v-flex>
                        <v-flex xs6>
                            <v-select
                                v-if="packageOptions.length"
                                item-text="text"
                                item-value="value"
                                :items="packageOptions"
                                :value="packageOptions[0]"
                                v-on:change="changeQuantity"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 pt-2>{{ $t('global.amount') }}</v-flex>
                        <v-flex xs6>
                            <v-layout row class="c-article-amount">
                                <v-btn flat small outline color="dark" class="btn__quantity"
                                       @click.native="quantityMinus">
                                    <v-icon size="16">fa-minus</v-icon>
                                </v-btn>

                                <v-text-field
                                        v-model="article.quantity"
                                        type="number"
                                        solo
                                        class="number--custom"
                                        :min="minimumQuantity"
                                        :step="requiredStep"
                                        hide-details
                                        :readonly="requiredStep > 1"
                                        :label="$t('home.amount')"
                                ></v-text-field>

                                <v-btn flat small outline color="dark" class="btn__quantity"
                                       @click.native="quantityPlus">
                                    <v-icon size="16">fa-plus</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-layout>
                        <v-flex v-if="article.error" xs12 class="error--text pt-1">
                            <div v-for="(item, index) in article.error" :key="index">
                                <v-icon color="error" class="mr-1">fa-exclamation-triangle</v-icon>
                                {{ item }}
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions class="d-block">
                    <v-layout column>
                        <v-flex xs12 mb-2>
                            <v-btn color="secondary" block depressed large @click.native="cartAndShop(article.productId)">
                                {{ $t('home.send_btn_shop') }}
                            </v-btn>
                        </v-flex>
                        <v-flex xs2></v-flex>
                        <v-flex xs12 mb-1>
                            <v-btn color="grey" block depressed outline large @click.native="cartAndOrder(article.productId)">
                                {{ $t('home.send_btn_order') }}
                            </v-btn>
                        </v-flex>
                        <v-btn class="btn-close--dialog position-absolute" large flat icon color="primary" @click.stop.native="closeArticleDialog">
                            <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="record" transform="translate(-332.000000, -14.000000)">
                                        <circle id="Oval" stroke-opacity="0.462862319" stroke="#D3D3D3" stroke-width="2" fill="#FFFFFF" cx="346" cy="28" r="13"></circle>
                                        <path d="M342.181656,31.8120679 C342.298733,31.9294081 342.462454,32 342.626643,32 C342.790364,32 342.942658,31.9413299 343.07163,31.8120679 L345.99856,28.8785616 L348.925489,31.8120679 C349.042566,31.9294081 349.206287,32 349.370476,32 C349.534197,32 349.686491,31.9413299 349.815463,31.8120679 C350.061512,31.5654656 350.061512,31.1666965 349.815463,30.932016 L346.888533,27.9985098 L349.815463,25.0650036 C350.061512,24.8184013 350.061512,24.4196322 349.815463,24.1849517 C349.569414,23.9383494 349.183433,23.9383494 348.937384,24.1849517 L346.010455,27.1184579 L343.083525,24.1849517 C342.837476,23.9383494 342.451496,23.9383494 342.205446,24.1849517 C341.959397,24.431554 341.959397,24.8303231 342.205446,25.0650036 L345.132376,27.9985098 L342.205446,30.932016 C341.935618,31.1786183 341.935618,31.5654656 342.181666,31.8120679 L342.181656,31.8120679 Z" id="Path" fill="#000000"></path>
                                    </g>
                                </g>
                            </svg>
                        </v-btn>
                    </v-layout>
                </v-card-actions>
            </template>
            <v-layout v-if="loading" column align-center pt-5 pb-5>
                <v-progress-circular
                        :size="36"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
                <h2 class="title text--secondary text-xs-center mt-3">{{ $t('home.processing') }}</h2>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'v-product-dialog',
  data() {
    return {
      open: false,
      loading: false,
      packingSize: 1,
    };
  },
  computed: {
    article() {
      return this.$store.state.article;
    },
    minimumQuantity() {
      const value = this.article.product ? parseInt(this.article.product.minimumQuantity, 10) / this.packingSize : null;
      return value || 1;
    },
    requiredStep() {
      const requiredMultiplier = this.article.product
        ? parseInt(this.article.product.requiredQuantityMultiplier, 10) / this.packingSize : 1;

      return requiredMultiplier >= 1 ? requiredMultiplier : 1;
    },
    packageSize() {
      return this.article.product ? parseInt(this.article.product.suggestedQuantityMultiplier, 10) : 0;
    },
    packageOptions() {
      if (this.packageSize > 1) {
        return [
          {
            text: this.$t('article.pieces'),
            value: 1,
          },
          {
            text: this.$t('article.package', [this.packageSize]),
            value: this.packageSize,
          },
        ];
      }

      return [];
    },
  },
  watch: {
    'article.code': function () {
      this.open = !!this.article.productId;

      // Reset initial values.
      if (this.open) {
        this.packingSize = 1;
      }
    },
  },
  methods: {
    cartAndOrder(value) {
      this.sendToCart(value, true);
    },
    cartAndShop(value) {
      this.sendToCart(value);
    },
    sendToCart(value, toCart = false) {
      this.loading = true;

      // Add to cart dispatch
      this.$store.dispatch('addToCart', {
        id: value,
        quantity: this.article.quantity * this.packingSize,
        redirect: true,
      }).then(() => {
        this.loading = false;
        this.closeArticleDialog();

        // Show success message
        this.$store.dispatch('setAlert', {
          message: this.$t('cart.message.product_added'),
          color: 'success',
        });

        if (toCart) {
          this.$router.push({ name: 'cart' }).catch(() => {});
        }

        this.$store.dispatch('clearAutocomplete');
      }).catch(() => {
        this.loading = false;
      });
    },

    closeArticleDialog() {
      this.open = false;
      this.$store.dispatch('clearArticleInfo');
    },

    // Helpers
    quantityMinus() {
      this.article.error = null;
      if (this.article.quantity > this.requiredStep && this.article.quantity > this.minimumQuantity) {
        this.article.quantity = parseInt(this.article.quantity, 10) - this.requiredStep;
      } else if (this.article.quantity <= this.requiredStep) {
        this.article.quantity = this.minimumQuantity;
      } else {
        this.article.error = [this.$t('cart.error_value_null')];
      }
    },
    quantityPlus() {
      this.article.error = null;
      if (parseInt(this.article.quantity, 10) < this.requiredStep) {
        this.article.quantity = this.requiredStep;
      } else {
        this.article.quantity += this.requiredStep;
      }
    },
    changeQuantity(item) {
      this.packingSize = item;

      let newValue = this.article.quantity;
      if (item === 1) {
        newValue = this.article.quantity * this.packageSize;
      } else {
        newValue = this.article.quantity / item;
      }

      newValue = Math.ceil(newValue);

      const min = this.minimumQuantity / item >= 1 ? this.minimumQuantity / item : 1;

      if (newValue < min) {
        newValue = min;
      }

      this.article.quantity = newValue;
    },
  },
};
</script>

<style lang="scss">
    .btn-close--dialog {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
    }
</style>
