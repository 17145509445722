import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';

import Home from './views/Home.vue';

import CartOverview from './views/Cart/Overview.vue';
import CartConfirmation from './views/Cart/Confirmation.vue';
import CartFinished from './views/Cart/Finished.vue';

import Contacts from './views/Contacts.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
    },
    {
      path: '/cart',
      name: 'cart',
      component: CartOverview,
    },
    {
      path: '/cart/confirmation',
      name: 'cart-confirmation',
      component: CartConfirmation,
    },
    {
      path: '/cart/finished',
      name: 'cart-finished',
      component: CartFinished,
    },
  ],
});
