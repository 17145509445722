<template>
    <v-dialog v-model="dialogUA">
        <v-card>
            <v-card-title class="headline pb-0">{{ $t('global.browser.title') }}</v-card-title>
            <v-card-text class="mb-3">
                <v-layout wrap>
                    <v-flex xs12>
                        <div v-if="$ua.os.name === 'iOS'">
                            <div v-html="$t('global.browser.ios')"></div>
                            <v-icon color='blue'>fab fa-safari</v-icon><span class="ml-2 title text--secondary">Safari</span>
                        </div>
                        <div v-else>
                            <div v-html="$t('global.browser.android')"></div>
                            <div class="mb-3"><v-icon color='gray'>fab fa-android</v-icon><span class="ml-2 title text--secondary">Android Browser</span></div>
                            <div><v-icon color='gray'>fab fa-chrome</v-icon><span class="ml-2 title text--secondary">Google Chrome</span></div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click.native="closeUADialog">
                    {{ $t('global.ok') }}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'v-compatibility-dialog',
  data() {
    return {
      dialogUA: false,
    };
  },
  created() {
    // Check for all IOS Browsers, except Safari
    if (this.$ua.os.name === 'iOS' && this.$ua.browser.name !== 'Mobile Safari' && this.$cookie.get('browser_prompt') !== 'false') {
      this.dialogUA = true;
    }
    // Check for all Android Browsers, except Chrome and default Android Browser
    if (this.$ua.os.name === 'Android' && (this.$ua.browser.name !== 'Chrome' && this.$ua.browser.name !== 'Android Browser') && this.$cookie.get('browser_prompt') !== 'false') {
      this.dialogUA = true;
    }
  },
  mounted() {

  },
  methods: {
    closeUADialog() {
      this.dialogUA = false;
      this.$cookie.set('browser_prompt', 'false', { expires: '3M' });
    },
  },
};
</script>
