<template>
  <v-container fluid>

    <template v-if="!loadingBarcode">
      <!-- CAMERA -->
      <v-multi-format-reader v-if="showScanner"
                     class="mb-2"
                     @reset="resetScanner"
                     @result="getScannerResult"
                     @cameraError="setCameraError"
      ></v-multi-format-reader>

      <!-- SHOW MANUAL INPUT BUTTON-->
      <v-code-input v-if="showScanner && !cameraError" @result="getScannerResult"></v-code-input>
    </template>

    <!-- LOADING PROCESSING-->
    <template v-else>
      <v-layout column align-center pt-5 pb-5>
        <v-progress-circular
                :size="36"
                color="primary"
                indeterminate
        ></v-progress-circular>
        <h2 class="title text--secondary text-xs-center mt-3">{{ $t('home.processing') }}</h2>
      </v-layout>
    </template>

    <!-- DAMAGE CHOICE VIEW -->
    <v-layout v-if="showDamageChoice" wrap>
      <template v-if="!loadingDamagePerson">
        <v-flex xs12>
          <h2 class="title title--vh title--damage mt-2 mb-4 text-xs-center">{{ $t('home.damage.title') }}</h2>
        </v-flex>
        <v-flex xs12>
          <v-btn class="c-btn--block px-3"
                 block
                 outline
                 large
                 color="secondary"
                 :disabled="disableDamageButtons"
                 @click.native="damageByUser('ENTRY_CUST')"
          >
            {{ $t('home.damage.correction') }}
          </v-btn>
        </v-flex>
        <v-flex xs12 mb-2>
          <v-btn class="c-btn--block px-3"
                 block
                 outline
                 large
                 color="secondary"
                 :disabled="disableDamageButtons"
                 @click.native="damageByHoecke"
          >
            {{ $t('home.damage.company') }}
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-btn class="c-btn--block px-3"
                 block
                 outline
                 large
                 color="secondary"
                 :disabled="disableDamageButtons"
                 @click.native="damageByUser('DMG_C')"
          >
            {{ $t('home.damage.person') }}
          </v-btn>
        </v-flex>
        <v-flex xs12 mb-2>
          <v-btn class="c-btn--block c-btn--block--cancel px-3"
                 block
                 outline
                 large
                 color="secondary"
                 :disabled="disableDamageButtons"
                 @click.native="resetScanner"
          >
            {{ $t('global.cancel') }}
          </v-btn>
        </v-flex>
      </template>
      <template v-else>
        <v-layout column align-center pt-5 pb-5>
          <v-progress-circular
                  :size="36"
                  color="primary"
                  indeterminate
          ></v-progress-circular>
          <h2 class="title text--secondary text-xs-center mt-3">{{ $t('home.processing') }}</h2>
        </v-layout>
      </template>
    </v-layout>

    <!-- TAKE PICTURE -->
    <v-layout v-if="showTakePicture" column wrap>
      <template v-if="!loadingTakePicture">
        <h2 class="title title--vh title--damage mt-2 mb-4 text-xs-center">{{ $t('home.picture.title') }}</h2>
        <v-layout align-center>
          <v-photo-camera @result="pictureTaken"></v-photo-camera>
        </v-layout>
      </template>
      <template v-else>
        <v-layout column align-center pt-5 pb-5>
          <v-progress-circular
                  :size="36"
                  color="primary"
                  indeterminate
          ></v-progress-circular>
          <h2 class="title text--secondary text-xs-center mt-3">{{ $t('home.processing') }}</h2>
        </v-layout>
      </template>
    </v-layout>

  </v-container>
</template>

<script>
import VPhotoCamera from '@/components/PhotoCamera.vue';
import VMultiFormatReader from '@/components/MultiFormatReader.vue';
import VCodeInput from '@/components/CodeInput.vue';
import { mapState } from 'vuex';

export default {
  components: {
    VMultiFormatReader,
    VPhotoCamera,
    VCodeInput,
  },
  data() {
    return {
      // Show
      showScanner: true,
      showDamageChoice: false,
      showTakePicture: false,

      // Loading
      loadingBarcode: false,
      loadingTakePicture: false,
      loadingDamagePerson: false,

      // States
      disableDamageButtons: false,
      qrCode: null,
      cameraError: false,
      errorCounter: 0,
    };
  },
  computed: {
    ...mapState(['personality']),
  },
  methods: {
    // Get the result from the scanner
    // When format is QR-CODE, show the damage choice view
    // When format is BARCODE addToCart and push route to cart
    getScannerResult(result) {
      // Check scan format and format
      switch (result.format) {
        case 7: // BARCODE
          if (this.personality.fittingsAllowed) {
            this.getArticleInfo(result.text);
          } else {
            this.$store.dispatch('setAlert', {
              message: this.$t('global.scan_type_invalid'),
              color: 'error',
            });
          }
          break;
        case 11: // QR CODE
          if (result.text !== this.$store.state.user.usedToken) {
            this.formatQR(result.text);
          } else {
            this.$store.dispatch('setAlert', {
              message: this.$t('global.login_scan'),
              color: 'error',
            });
            this.showScanner = false;
            this.$nextTick(() => { this.showScanner = true; });
          }
          break;
        default:
          this.errorCounter += 1;
          this.$store.dispatch('setAlert', {
            message: this.$t('global.format_error'),
            color: 'error',
          });
          this.showScanner = false;
          this.$nextTick(() => { this.showScanner = true; });
      }
    },

    // FLOW 1: BARCODE
    // 1. Get product information, user selects quantity
    // 2. Send formatBarcode() to server
    // 3. Return the user to camera after success, for another scan
    getArticleInfo(value) {
      this.loadingBarcode = true;

      // Add to cart dispatch
      this.$store.dispatch('getArticleInfo', { value, type: 'ean' }).then(() => {
        this.loadingBarcode = false;
      }).catch(() => {
        this.errorCounter += 1;
        this.loadingBarcode = false;
      });
    },
    formatBarcode(value) {
      this.loadingBarcode = true;

      // Add to cart dispatch
      this.$store.dispatch('addToCart', {
        id: value,
        quantity: this.article.quantity,
        redirect: false,
      }).then(() => {
        this.loadingBarcode = false;

        // Show success message
        this.$store.dispatch('setAlert', {
          message: this.$t('cart.message.product_added'),
          color: 'success',
        });
      }).catch(() => {
        this.errorCounter += 1;
        this.loadingBarcode = false;
        this.$nextTick(() => { this.showScanner = true; });
      });
    },

    // FLOW 2: QR CODE
    formatQR(value) {
      this.qrCode = value;
      this.showScanner = false;
      this.showDamageChoice = true;
      window.scrollTo(0, 0);
    },

    // Damage is done by Van Hoecke, user must take picture(s)
    damageByHoecke() {
      this.showDamageChoice = false;
      this.showTakePicture = true;
    },
    pictureTaken(result) {
      const view = this;
      this.$store.dispatch('setLoading', true);
      this.loadingTakePicture = true;
      const photos = [];

      result.forEach((photo) => {
        photos.push(view.dataURItoBlob(photo));
      });

      // ADD REPORT TO DAMAGE
      this.$store.dispatch('addReportDamage', {
        image: photos,
        code: this.qrCode,
        type: 'DMG_VH',
      }).then(() => {
        this.$store.dispatch('setLoading', false);
        this.loadingTakePicture = false;
        this.$router.push({ name: 'cart' }).catch(() => {});

        // Show success message
        this.$store.dispatch('setAlert', {
          message: this.$t('cart.message.damage_added'),
          color: 'success',
        });
      }).catch(() => {
        this.errorCounter += 1;
        this.showDamageChoice = false;
        this.loadingTakePicture = false;
      });
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },

    // Damage is done by the customer, order the product
    damageByUser(type) {
      this.disableDamageButtons = true;
      this.loadingDamagePerson = true;
      this.$store.dispatch('setLoading', true);

      // ADD REPORT TO DAMAGE
      this.$store.dispatch('addReportDamage', {
        code: this.qrCode,
        type,
      }).then(() => {
        this.showDamageChoice = false;
        this.$store.dispatch('setLoading', false);
        this.loadingDamagePerson = false;
        this.disableDamageButtons = false;
        this.$router.push({ name: 'cart' }).catch(() => {});

        // Show success message
        this.$store.dispatch('setAlert', {
          message: this.$t('cart.message.damage_added'),
          color: 'success',
        });
      }).catch(() => {
        this.$store.dispatch('setLoading', false);
        this.errorCounter += 1;
        this.disableDamageButtons = false;
        this.loadingDamagePerson = false;
        this.showDamageChoice = false;
        this.showScanner = false;
        this.$nextTick(() => { this.showScanner = true; });
      });
    },

    // Camera errors and resetting
    setCameraError(value) {
      this.cameraError = value;
    },
    resetScanner() {
      this.showDamageChoice = false;
      this.showTakePicture = false;
      this.showScanner = false;
      this.$nextTick(() => { this.showScanner = true; });
    },

    // Clear all vars when view is destroy, prevents wrong data being loaded on reboot
    beforeDestroy() {
      this.errorCounter = 0;
      this.showScanner = true;
      this.showDamageChoice = false;
      this.showTakePicture = false;
    },
  },
};
</script>

<style lang="scss">
  .theme--light.c-btn--block {
    background: #FFFFFF !important;
    border: 1px solid rgba(211,211,211,0.46);
    box-shadow: 0 2px 4px 0 rgba(226,226,226,0.26);
    border-radius: 4px;
    height: 90px;
    font-size: 16px;
    text-transform: initial;
    font-weight: 400;
    &--cancel {
      height: 45px;
      background: transparent !important;
      border-color: var(--accent-color);
      .v-btn__content { color: var(--accent-color) !important; }
    }
  }
  .theme--light.c-btn--block .v-btn__content {
    white-space: initial;
    color: #737373;
    font-weight: 500;
  }

  .title--damage {
    line-height: 1.25 !important
  }
</style>
