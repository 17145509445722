<template>
    <v-container fluid class="c-container--login px-0" fill-height>
        <!-- LOADING -->
        <v-layout v-if="loading" column align-center pt-5 pb-5>
            <v-progress-circular
                    :size="36"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
            <h2 class="title text--secondary text-xs-center mt-3">{{ $t('login.state_loading') }}</h2>
        </v-layout>

        <!-- LOGIN -->
        <v-layout v-else wrap column>
            <!-- LOGIN SCANNER -->
            <div :class="scannerClass">
                <h2 class="title title--vh mb-2 text-xs-center">{{ $t('login.title') }}</h2>
                <h3 class="caption mb-3 text-xs-center">{{ $t('login.title_caption') }}</h3>
                <v-multi-format-reader
                        class="scanner--login"
                        width="128"
                        height="126"
                        force-height
                        :enable-barcode="false"
                        :torch="false"
                        @result="getScannedResult"
                ></v-multi-format-reader>
                <span class="centered-dot" v-if="personality.pwaLoginForm">{{ $t('global.or') }}</span>
            </div>

            <!-- LOGIN CLASSIC-->
            <div class="login--classic" v-if="personality.pwaLoginForm">
                <v-layout column>
                    <v-flex xs12>
                        <h2 class="title title--vh mt-2 mb-2 text-xs-center">{{ $t('global.login') }}</h2>
                        <p class="caption text-xs-center">
                            {{ $t('login.caption') }}
                        </p>
                    </v-flex>
                    <v-form ref="form" v-model="valid" @submit.prevent="submit({ name: username, pass: password })">
                        <v-flex xs12>
                            <v-text-field
                                    v-model="username"
                                    class="v-text-field--vh"
                                    :label="$t('global.username')"
                                    single-line
                                    solo
                                    :rules="requiredRules"
                                    color="success"
                                    required
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                    v-model="password"
                                    class="v-text-field--vh"
                                    :label="$t('global.password')"
                                    single-line
                                    type="password"
                                    solo
                                    :rules="requiredRules"
                                    color="success"
                                    required
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 text-xs-center>
                            <v-btn :disabled="!valid" depressed large color="secondary" type="submit" class="mt-0">
                                {{ $t('global.login') }}
                            </v-btn>
                        </v-flex>
                    </v-form>
                </v-layout>
            </div>
        </v-layout>

        <!-- shows a warning when using and unsupported browser -->
        <v-compatibility-dialog></v-compatibility-dialog>
    </v-container>
</template>

<script>
import VMultiFormatReader from '@/components/MultiFormatReader.vue';
import VCompatibilityDialog from '@/components/Dialog/CompatibilityDialog.vue';
import { mapState } from 'vuex';

export default {
  scanner: null,
  components: {
    VMultiFormatReader,
    VCompatibilityDialog,
  },
  data() {
    return {
      loading: false,
      valid: false,
      username: null,
      password: null,
      requiredRules: [
        (v) => !!v || this.$t('global.required_field'),
      ],
    };
  },
  computed: {
    ...mapState(['personality']),
    scannerClass() {
      return {
        'login--code': true,
        'login--code--no-form': !this.personality.pwaLoginForm,
      };
    },
  },
  methods: {
    submit(data) {
      this.loading = true;
      this.$store.dispatch('login', data).then(() => {
        this.$gtm.trackEvent({
          event: 'Login',
          action: data.token ? 'QR' : 'Default',
          external_ip: this.personality.external_ip,
        });
        this.$router.push('/').catch(() => {});
      }).catch(() => {
        this.loading = false;
      });
    },
    getScannedResult(result) {
      this.submit({ token: result.text });
    },
  },
};
</script>

<style lang="scss">
    .c-container--login {
        padding-top: 85px;
        padding-bottom: 0;
    }
    .login--code {
        border-bottom: 1px solid rgba(211, 211, 211, 0.6);
        &--no-form {
          border:none;
          .scanner--login {
            width: 80vw;
            height: 80vw;
            video {
              height: 100% !important;
            }
          }
        }
    }
    .login--classic {
        padding: 20px;
        padding-bottom: 0;
        .v-input__slot {
          background-color: #fff !important;
        }
    }
    .scanner--login {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 130px;
        height: 130px;
        overflow: hidden;
        border-radius: 6px;
    }
    .centered-dot {
        padding: 0px 8px 0px 8px;
        position: relative;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        min-width: 32px;
        height: 32px;
        text-align: center;
        line-height: 2;
        font-size: 15px;
        border-radius: 50%;
        background: #FFFFFF;
        color: rgba(35,39,40,.5);
        border: 1px solid rgba(211,211,211,0.46);
        box-shadow: inset 0 1px 3px 0 rgba(222,222,222,0.50);
    }
</style>
