import 'webrtc-adapter/out/adapter_no_edge_no_global'; // VHPWA-[107,89,112,96], Fix for several unsupported issues / quirks
import Vue from 'vue';
// import LogRocket from 'logrocket';
import './plugins/vuetify';
import VueCurrencyFilter from 'vue-currency-filter';
import UAParser from 'ua-parser-js';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import i18n from './i18n';

Vue.config.productionTip = false;

// Init log rocket
// if (process.env.NODE_ENV !== 'development') {
//   LogRocket.init('remfxo/van-hoecke-pwa');
// }

// Set parser
const parser = new UAParser();
Vue.prototype.$ua = parser.getResult();

// Load vue directives
Vue.use(require('vue-cookie'));
Vue.use(require('vue-moment'));

Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
});

// Router configuration
router.beforeEach((to, from, next) => {
  if (store.state.isLoggedIn || (!store.state.isLoggedIn && to.name === 'login')) {
    next();
  } else {
    next('/login');
  }
});

// Start Vue instance
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
