<template>
    <v-container fluid class="container__contacts">
        <v-layout column>
            <!-- INTERNAL MANAGER -->
            <v-card v-if="internalManager" class="mb-4">
                <v-layout>
                    <v-flex xs4>
                        <v-img
                                :src="internalManager.picture"
                                height="150px">
                        </v-img>
                    </v-flex>
                    <v-flex xs8>
                        <v-card-title class="pt-2 d-block">
                            <div class="subheading pl-2 pt-2">{{ internalManager.firstName }} {{ internalManager.lastName }}</div>
                            <div class="caption pl-2 mb-3">{{ internalManager.function }}</div>
                            <div class="caption pl-2 mb-1">
                                <span class="card__label">{{ $t('global.mobile') }}:</span>
                                <a :href="'tel:'+internalManager.phone">
                                    <span>{{ internalManager.phone }}</span>
                                </a>
                            </div>
                            <div class="caption pl-2">
                                <span class="card__label">{{ $t('global.email') }}:</span>
                                <a :href="'mailto: '+internalManager.email">
                                    <span>{{ internalManager.email }}</span>
                                </a>
                            </div>
                        </v-card-title>
                    </v-flex>
                </v-layout>
            </v-card>

            <!-- EXTERNAL MANAGER-->
            <v-card v-if="externalManager" class="mb-4">
                <v-layout>
                    <v-flex xs4>
                        <v-img :src="externalManager.picture" height="150px"></v-img>
                    </v-flex>
                    <v-flex xs8>
                        <v-card-title class="pt-2 d-block">
                            <div class="subheading pl-2 pt-2">{{ externalManager.firstName }} {{ externalManager.lastName }}</div>
                            <div class="caption pl-2 mb-3">{{ externalManager.function }}</div>
                            <div class="caption pl-2 mb-1">
                                <span class="card__label">{{ $t('global.mobile') }}:</span>
                                <a :href="'tel:'+externalManager.phone">
                                    <span>{{ externalManager.phone }}</span>
                                </a>
                            </div>
                            <div class="caption pl-2">
                                <span class="card__label">{{ $t('global.email') }}:</span>
                                <a :href="'mailto: '+externalManager.email">
                                    <span>{{ externalManager.email }}</span>
                                </a>
                            </div>
                        </v-card-title>
                    </v-flex>
                </v-layout>
            </v-card>

            <v-card v-for="contactInfo in contactInfos" v-bind:key="contactInfo.id" class="mb-4">
              <v-layout>
                <v-flex>
                  <v-card-title class="pt-2 d-block">
                    <div class="subheading pl-2 pt-2"><strong>{{ contactInfo.title }}</strong></div>
                    <div class="caption pl-2 mb-3"></div>
                    <div class="caption pl-2 mb-2">
                      <span class="mr-1">{{ $t('global.phone_number') }}:</span>
                      <a :href="'tel:'+contactInfo.phoneNumber">
                        <span>{{ contactInfo.phoneNumber }}</span>
                      </a>
                    </div>
                    <div class="caption pl-2">
                      <span class="card__label card__label__contact-hours">{{ $t('global.contact_hours') }}:</span>
                      <div class="caption mb-3">
                        <div class="card__opening-hours" v-html="contactInfo.openingHours"></div>
                      </div>
                    </div>
                  </v-card-title>
                </v-flex>
              </v-layout>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('setLoading', true);

    this.$store.dispatch('getContacts').then(() => {
      this.$store.dispatch('setLoading', false);
    });
  },
  computed: {
    internalManager() {
      return this.$store.state.contacts && this.$store.state.contacts.internalManager ? this.$store.state.contacts.internalManager : null;
    },
    externalManager() {
      return this.$store.state.contacts && this.$store.state.contacts.externalManager ? this.$store.state.contacts.externalManager : null;
    },
    contactInfos() {
      return this.$store.state.contacts && this.$store.state.contacts.contactInfos ? this.$store.state.contacts.contactInfos : null;
    },
  },
};
</script>

<style lang="scss">
  .container__contacts {
    .card__label {
      display: inline-block;
      width: 45px;
    }

    .card__label__contact-hours {
      display: block;
      width: 100%;
    }

    .card__opening-hours {
      display: flex;

      > div:first-of-type {
        width: 20%;
      }
    }
  }
</style>
