<template>
    <div>
        <template v-if="small">
            <router-link :to="{ name: 'home' }" class="c-logo" @click.native="logoClicked">
                <svg width="24px" height="32px" viewBox="0 0 24 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="header-vanhoecke-v4" transform="translate(-15.000000, -11.000000)" fill="#FFFFFF">
                            <g id="Group-4" transform="translate(15.000000, 11.500000)">
                                <polygon id="Fill-6" points="16.6721114 15.9847749 16.6721114 31 24 31 24 2 12 15.9847749"></polygon>
                                <polygon id="Fill-8" points="7.23640073 -1.8657701e-15 0 -1.8657701e-15 0 29 12 14.8670774 7.20648232 14.8670774"></polygon>
                            </g>
                        </g>
                    </g>
                </svg>
            </router-link>
        </template>
        <template v-else>
            <div class="c-logo--large">
                <simple-svg v-if="personality.personalityId !== 1"
                    :src="personality.logo"
                    width="100%"
                    height="100%"
                    :fill="'#ffffff'"
                    custom-class-name="c-logo--white"
                />

                <!-- if vanhoecke -->
                <svg v-if="personality.personalityId === 1" class="c-logo--white" width="121px" height="26px" viewBox="0 0 121 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="UX-PWA-V2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Login-stap-1" transform="translate(-127.000000, -91.000000)" fill="#FFFFFF">
                        <g id="Group-3" transform="translate(127.000000, 91.000000)">
                            <path d="M120.000165,6.86656746 C119.521078,6.86656746 119.143991,6.47387566 119.143991,6 C119.143991,5.53141534 119.513308,5.13343254 120.000165,5.13343254 C120.484212,5.13343254 120.856009,5.53141534 120.856009,6 C120.856009,6.48181217 120.470987,6.86656746 120.000165,6.86656746 M120.00281,5 C119.440073,5 119,5.45535714 119,6 C119,6.54695767 119.442552,7 120.00281,7 C120.547198,7 121,6.55522487 121,6 C121,5.45271164 120.557778,5 120.00281,5" id="Fill-1"></path>
                            <path d="M118.319809,6.9247001 L118.319809,6.23384882 L119.017074,6.23384882 C119.186341,6.23384882 119.645309,6.23384882 119.645309,6.55846221 C119.645309,6.9247001 119.127226,6.9247001 118.959427,6.9247001 L118.319809,6.9247001 Z M119.494033,7.03916751 L119.494033,7.02991762 C119.837342,6.9657465 119.964384,6.76427229 119.964384,6.54487643 C119.964384,6 119.227097,6 119.069947,6 L118,6 L118,8 L118.319809,8 L118.319809,7.15334586 L118.953552,7.15334586 C119.53479,7.15334586 119.604553,7.31377367 119.604553,7.62971528 C119.615935,7.8811967 119.633193,7.94045382 119.680191,8 L120,8 C119.941619,7.91790721 119.941619,7.87657176 119.918854,7.53808354 C119.906738,7.28168811 119.842482,7.11692441 119.494033,7.03916751 L119.494033,7.03916751 Z" id="Fill-4"></path>
                            <polygon id="Fill-6" points="53.6721114 12.5380741 53.6721114 26 61 26 61 -1.67618064e-15 49 12.5380741"></polygon>
                            <polygon id="Fill-8" points="44.2364007 -1.6727594e-15 37 -1.6727594e-15 37 26 49 13.3291039 44.2064823 13.3291039"></polygon>
                            <path d="M105.3516,7 C105.3516,7 103.735871,9.39307505 101,12.1780351 C104.044493,15.3619449 105.3516,18 105.3516,18 L108,18 C108,18 106.090973,14.6205633 103.649815,12.1780351 C105.689929,10.210922 108,7 108,7 L105.3516,7 Z" id="Fill-10"></path>
                            <polygon id="Fill-12" points="98 18 100 18 100 7 98 7"></polygon>
                            <path d="M5.67190687,15.8093097 L5.65468219,15.8093097 C4.55479551,13.0831035 3.40300814,9.77358597 2.6469352,7 L0,7 C1.117338,10.5931726 2.49213969,14.3120679 4.10786031,18 L6.92658906,18 C8.54230967,14.154551 9.86566395,10.5931726 11,7 L8.62820645,7 C7.87190687,9.61586126 6.87491501,12.6417237 5.67190687,15.8093097" id="Fill-14"></path>
                            <path d="M13.5872294,13.8080592 C14.1313333,12.2478227 14.7263278,10.6720005 15.3383606,9.08038464 L15.3553989,9.06438328 C15.9676558,10.6720005 16.5626503,12.232237 17.1067542,13.8080592 L13.5872294,13.8080592 Z M14.0974809,7 C12.5671748,10.6404134 11.2242897,14.2178603 10,18 L12.2781967,18 C12.5162842,17.1803979 12.8052623,16.298245 13.0942404,15.4312622 L17.6167815,15.4312622 C17.8887213,16.2822436 18.1606612,17.1963992 18.4157869,18 L21,18 C19.8097868,14.4227608 18.3987486,10.687794 16.8175519,7 L14.0974809,7 Z" id="Fill-15"></path>
                            <polygon id="Fill-16" points="30.8939016 14.9899309 30.8581931 14.9899309 26.1327748 7 23 7 23 18 25.1060984 18 25.1060984 9.85234443 25.1415735 9.85234443 29.973417 18 33 18 33 7 30.8939016 7"></polygon>
                            <path d="M69.0882181,16.3183123 C66.3982652,16.3183123 65.5130499,14.772686 65.5130499,12.3787235 C65.5130499,10.3939443 66.3623245,8.68188753 68.9113151,8.68188753 C71.6017348,8.68188753 72.4864833,10.2878524 72.4864833,12.5759227 C72.4864833,14.6060557 71.6554125,16.3183123 69.0882181,16.3183123 M69.0527442,7 C65.1419736,7 63,9.1967633 63,12.5607382 C63,16.1668665 65.0175814,18 68.9295188,18 C72.9287409,18 75,15.8485905 75,12.3937082 C75,8.92424077 72.8752966,7 69.0527442,7" id="Fill-17"></path>
                            <polygon id="Fill-18" points="80.2711221 13.1304832 84.2998424 13.1304832 84.2998424 11.4915177 80.2711221 11.4915177 80.2711221 8.67055201 84.9317853 8.67055201 84.9317853 7 78 7 78 18 85 18 85 16.329448 80.2711221 16.329448"></polygon>
                            <polygon id="Fill-19" points="112.271122 13.1304832 116.300293 13.1304832 116.300293 11.4915177 112.271122 11.4915177 112.271122 8.67055201 116.931785 8.67055201 116.931785 7 110 7 110 18 117 18 117 16.329448 112.271122 16.329448"></polygon>
                            <path d="M89.2083853,12.4998995 C89.2083853,10.2909795 90.3205203,8.72153124 92.1737393,8.72153124 C92.9606446,8.72153124 93.6249523,8.85894837 94.3356923,9.17858382 L94.9533641,7.5940679 C94.0420284,7.16755246 93.1459664,7 92.127307,7 C89.0688848,7 87,9.07210564 87,12.6523843 C87,16.1719906 88.9613573,18 92.1737393,18 C93.1308963,18 94.1968027,17.7563056 95,17.375395 L94.4904666,15.8061476 C93.8721839,16.0803792 93.1459664,16.2634011 92.4048825,16.2634011 C90.4744801,16.2634011 89.2083853,15.0746626 89.2083853,12.4998995 Z" id="Fill-20"></path>
                        </g>
                    </g>
                </g>
            </svg>
            </div>
        </template>
    </div>
</template>

<script>
import { SimpleSVG } from 'vue-simple-svg';
import { mapState } from 'vuex';

export default {
  name: 'v-logo',
  components: {
    'simple-svg': SimpleSVG,
  },
  props: {
    small: { type: Boolean, default: true },
  },
  computed: {
    ...mapState(['personality']),
  },
  methods: {
    logoClicked() {
      // Clear autocomplete state
      this.$store.dispatch('clearAutocomplete');
    },
  },
};
</script>

<style lang="scss">
    .c-logo--small {
        svg {
            position: relative;
            top: 3px;
            width: 24px;
            height: auto;
            fill: currentColor;
        }
    }
    .c-logo--large {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: var(--accent-color);
        height: 55px;
        text-align: center;

        .c-logo--white {
          fill: white;
          padding: 15px 0;
          width: 100%;
          height: 100%;

            path, polygon {
                fill: white;
            }
        }
    }
</style>
