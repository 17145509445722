<template><div></div></template>

<script>
import LogRocket from 'logrocket';

export default {
  name: 'v-logrocket',
  created() {
    // Identify users for logrocket
    if (this.$store.state.user && this.$store.state.user.current_user) {
      LogRocket.identify(this.$store.state.user.current_user.uid, {
        name: this.$store.state.user.current_user.name,
      });
    }
  },
};
</script>
