<template>
    <v-layout wrap :class="{'c-cart--item': true, 'c-cart--prepare': readOnly }">
        <template v-if="loading">
            <v-layout column align-center pt-2 pb-2>
                <v-progress-circular
                        :size="24"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
                <h2 class="body-2 text--secondary text-xs-center mt-2">{{ $t('cart.loading_delete') }}</h2>
            </v-layout>
        </template>
        <template v-else>
            <v-flex xs2>
                <img v-if="item.image" class="c-cart--image" :src="item.image" alt="">
                <img v-else-if="item.imageData" class="c-cart--image" :src="item.imageData.small" alt="">
                <v-dialog v-if="item.damageReportType && !readOnly" v-model="dialogInfo" scrollable>
                    <v-btn slot="activator" flat icon small color="secondary" class="cart__information--modal">
                        <v-icon>fa-info-circle</v-icon>
                    </v-btn>
                    <v-card>
                        <v-card-title v-if="item.name" class="font-weight-bold">{{ item.name }}</v-card-title>
                        <v-card-title v-else-if="item.flatConfiguration" class="font-weight-bold">{{ item.flatConfiguration[`${i18n.locale}`].groepering }}</v-card-title>
                        <v-card-text class="pt-0">
                            <v-layout wrap v-if="item.id">
                                <v-flex xs12><strong>{{ $t('cart.info.id') }}:</strong> {{ item.id }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.configurator">
                                <v-flex xs12 class="font-weight-bold"><strong>{{ $t('cart.info.configurator') }}:</strong> {{ item.flatConfiguration.configurator }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.cabinetwidth">
                                <v-flex xs12><strong>{{ $t('cart.info.corpus_height') }}:</strong> {{ item.flatConfiguration.cabinetwidth }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.cabinetwallthickness">
                                <v-flex xs12><strong>{{ $t('cart.info.corpus_width') }}:</strong> {{ item.flatConfiguration.cabinetwallthickness }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.frontattachment">
                                <v-flex xs12><strong>{{ $t('cart.info.front') }}:</strong> {{ item.flatConfiguration.frontattachment }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.boxsystemcode">
                                <v-flex xs12><strong>{{ $t('cart.info.drawer_code') }}:</strong> {{ item.flatConfiguration.boxsystemcode }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.boxsystemdepth">
                                <v-flex xs12><strong>{{ $t('cart.info.drawer_depth') }}:</strong> {{ item.flatConfiguration.boxsystemdepth }}</v-flex>
                            </v-layout>
                            <v-layout wrap v-if="item.flatConfiguration?.miscinfo">
                                <v-flex xs12><strong>{{ $t('cart.info.various') }}:</strong> {{ item.flatConfiguration.miscinfo | convertMisc }}</v-flex>
                            </v-layout>
                            <strong v-if="item.damageImages" class="mb-1 d-block">{{ $t('cart.info.picture') }}</strong>
                            <v-carousel
                                    v-if="item.damageImages"
                                    :cycle="false"
                                    :hide-delimiters="true"
                                    class="damage-carousel"
                            >
                                <v-carousel-item
                                        v-for="(image,i) in item.damageImages"
                                        :key="i"
                                        :src="image"
                                ></v-carousel-item>
                            </v-carousel>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" outline large dark depressed @click.native="dialogInfo = false">{{ $t('global.close') }}</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-flex xs10>
                <v-layout row wrap>
                    <!-- CONTENT: IMAGE, TITLE, DELETE -->
                    <v-flex xs10>
                        <truncate :clamp="$t('cart.item.show_all')"
                                  :less="$t('cart.item.show_less')"
                                  :length="70"
                                  action-class="caption"
                                  :text="item.name"
                        ></truncate>
                    </v-flex>
                    <v-flex xs2 class="text-xs-right">
                        <!-- ITEM DELETE -->
                        <v-dialog v-if="!readOnly" v-model="dialogDelete" persistent max-width="290">
                            <v-btn slot="activator" flat small icon color="primary" style="top: -8px; margin-bottom: 0; height: 25px;">
                                <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="record" transform="translate(-332.000000, -14.000000)">
                                            <circle id="Oval" stroke-opacity="0.462862319" stroke="#D3D3D3" stroke-width="2" fill="#FFFFFF" cx="346" cy="28" r="13"></circle>
                                            <path d="M342.181656,31.8120679 C342.298733,31.9294081 342.462454,32 342.626643,32 C342.790364,32 342.942658,31.9413299 343.07163,31.8120679 L345.99856,28.8785616 L348.925489,31.8120679 C349.042566,31.9294081 349.206287,32 349.370476,32 C349.534197,32 349.686491,31.9413299 349.815463,31.8120679 C350.061512,31.5654656 350.061512,31.1666965 349.815463,30.932016 L346.888533,27.9985098 L349.815463,25.0650036 C350.061512,24.8184013 350.061512,24.4196322 349.815463,24.1849517 C349.569414,23.9383494 349.183433,23.9383494 348.937384,24.1849517 L346.010455,27.1184579 L343.083525,24.1849517 C342.837476,23.9383494 342.451496,23.9383494 342.205446,24.1849517 C341.959397,24.431554 341.959397,24.8303231 342.205446,25.0650036 L345.132376,27.9985098 L342.205446,30.932016 C341.935618,31.1786183 341.935618,31.5654656 342.181666,31.8120679 L342.181656,31.8120679 Z" id="Path" fill="#000000"></path>
                                        </g>
                                    </g>
                                </svg>
                            </v-btn>
                            <v-card>
                                <v-card-title class="d-block text-xs-center pb-1">
                                    <h2 class="title title--vh">{{ $t('cart.card_title') }}</h2>
                                </v-card-title>
                                <v-card-text>{{ $t('cart.card_text') }}</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="grey darken-1" flat @click.native="dialogDelete = false">{{ $t('global.cancel') }}</v-btn>
                                    <v-btn color="red" depressed large dark @click.native="deleteItem">
                                        {{ $t('global.delete') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-flex>

                    <!-- AMOUNT -->
                    <v-flex v-if="!readOnly" xs7>
                        <v-layout row class="c-article-amount">
                            <v-btn v-if="!readOnly" flat small outline color="dark" class="btn__quantity"
                                   :disabled="(!!item.damageReportType) || !!(item.configurator)"
                                   @click.native="quantityMinus">
                                <v-icon size="16">fa-minus</v-icon>
                            </v-btn>

                            <v-text-field
                                    v-model="item.quantity"
                                    type="number"
                                    solo
                                    class="number--custom"
                                    :min="minimumQuantity"
                                    :step="requiredStep"
                                    hide-details
                                    :readonly="requiredStep > 1"
                                    :disabled="!!item.damageReportType || readOnly || !!(item.configurator)"
                            ></v-text-field>

                            <v-btn v-if="!readOnly" flat small outline color="dark" class="btn__quantity"
                                   :disabled="(!!item.damageReportType) || !!(item.configurator)"
                                   @click.native="quantityPlus">
                                <v-icon size="16">fa-plus</v-icon>
                            </v-btn>
                        </v-layout>
                    </v-flex>
                    <v-flex v-else xs7 d-inline-flex>
                        <strong>{{ $t('global.amount') }}: {{ item.quantity }}</strong>
                    </v-flex>

                    <!-- TOTAL PRICE -->
                    <v-flex xs5 class="pt-2">
                        <div v-if="(totalPrice >= 0 && item.damageReportType) || (!item.damageReportType && totalPrice > 0)"
                             :class="{'subheading font-weight-medium text-xs-right': true, 'pt-1 pr-3': !readOnly}">{{ totalPrice | currency }}</div>
                        <div v-if="!item.damageReportType && totalPrice <=0"
                             :class="{'subheading font-weight-medium text-xs-right': true, 'pt-1 pr-3': !readOnly}">
                            <div class="spinner">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>

        <v-flex v-if="error" xs12 class="error--text pt-1">
            <div v-for="(item, index) in error" :key="index">
                <v-icon color="error" class="mr-1">fa-exclamation-triangle</v-icon>
                {{ item }}
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
import truncate from 'vue-truncate-collapsed';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'CartItem',
  components: {
    truncate,
  },
  props: {
    item: Object,
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      dialogDelete: false,
      dialogInfo: false,
      error: null,
    };
  },
  computed: {
    ...mapState(['personality']),
    totalPrice() {
      return this.item.quantity * Number(this.item.unitPrice);
    },
    minimumQuantity() {
      return this.item.minimumQuantity === 0 ? 1 : this.item.minimumQuantity;
    },
    requiredStep() {
      return this.item.requiredQuantityMultiplier ? this.item.requiredQuantityMultiplier : 1;
    },
  },
  watch: {
    'item.quantity': function () {
      this.updateQuantity();
    },
  },
  methods: {
    quantityMinus() {
      this.error = null;
      if (this.item.quantity !== this.requiredStep && this.item.quantity > this.minimumQuantity) {
        this.item.quantity = parseInt(this.item.quantity, 10) - this.requiredStep;
      } else {
        this.error = [this.$t('cart.error_value_null')];
      }
    },
    quantityPlus() {
      this.error = null;
      this.item.quantity = parseInt(this.item.quantity, 10) + this.requiredStep;
    },
    updateQuantity: debounce(function updateQty() {
      this.error = null;
      this.$store.dispatch('updateQuantity', this.item)
        .then((result) => {
          this.error = result && result.message && result.message.error.length ? result.message.error : null;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    deleteItem() {
      this.loading = true;
      this.dialogDelete = false;
      this.$store.dispatch('deleteFromCart', this.item).then((output) => {
        this.loading = false;
        if (output) {
          this.$store.dispatch('setAlert', {
            message: this.$t('cart.deleted', { name: this.item.name }),
            color: 'success',
          });
        }
      });
    },

  },
  filters: {
    convertMisc(value) {
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return Object.values(value).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
    .c-cart--item {
        border-top: 1px solid hsla(0,0%,79%,.5);
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .c-cart--image {
        max-width: 85%;
    }
    .cart__information--modal {
        position: absolute;
        left: 16px;
    }

    .spinner > div {
        width: 9px;
        height: 9px;
        background-color: #333;

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(0.8) }
    }

    @keyframes sk-bouncedelay {
        0%, 80%, 100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        } 40% {
              -webkit-transform: scale(0.8);
              transform: scale(0.8);
          }
    }
</style>
